import React from 'react';
import { TableOptions, useTable } from 'react-table';
import {
  Table as ChakraTable,
  TableProps as ChakraTableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Spinner,
} from '@chakra-ui/react';

import { PaginationTable } from './Pagination';

import { Metadata } from '@/modules/event/event';

export interface Props<T extends Record<string, unknown>>
  extends TableOptions<T>,
    ChakraTableProps {
  isLoading: boolean;
  metadata?: Metadata | null;
}

function CustomTable<T extends Record<string, unknown>>(props: Props<T>) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable<T>({
      columns: props.columns,
      data: props.data,
    });
  const tableProps = getTableProps ? getTableProps() : {};
  const tableBodyProps = getTableBodyProps ? getTableBodyProps() : {};
  return (
    <>
      <ChakraTable as="table" {...props} {...tableProps}>
        <Thead>
          {headerGroups?.map((headerGroup) => (
            // {...spread} already include `key`
            // eslint-disable-next-line react/jsx-key
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                // {...spread} already include `key`
                // eslint-disable-next-line react/jsx-key
                <Th {...column.getHeaderProps()} id={`${index}${column}`}>
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...tableBodyProps}>
          {props.isLoading && <Spinner />}
          {rows?.map((row) => {
            if (prepareRow) {
              prepareRow(row);
            }
            return (
              // {...spread} already include `key`
              // eslint-disable-next-line react/jsx-key
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    // {...spread} already include `key`
                    // eslint-disable-next-line react/jsx-key
                    <Td
                      {...cell.getCellProps()}
                      minW={cell.column.minWidth}
                      w={cell.column.width}
                      maxW={cell.column.maxWidth}
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
      <Box>
        {props.metadata?.pagination && (
          <PaginationTable pagination={props?.metadata?.pagination} />
        )}
      </Box>
    </>
  );
}

export { CustomTable };
