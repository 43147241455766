import React, { useEffect } from 'react';
import {
  Pagination as PaginationUI,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationPageGroup,
} from '@ajna/pagination';
import { Pagination } from 'modules/event/event';
import { HStack, Text } from '@chakra-ui/layout';
import { useRouter } from 'next/dist/client/router';
import { Select } from '@chakra-ui/select';

import { QueryParams } from '../Home/HomeContainer';

type Props = {
  pagination?: Pagination | null;
  customPageSize?: Array<number>;
};

function PaginationTable({ pagination, customPageSize = [5, 10, 15] }: Props) {
  const { currentPage, setCurrentPage, pagesCount, pages, setPageSize } =
    usePagination({
      pagesCount: pagination?.totalPage || 0,
      initialState: {
        currentPage: pagination?.page || 1,
        pageSize: pagination?.totalPage || 0,
      },
      total: pagination?.totalPage,
      limits: {
        inner: 2,
        outer: 2,
      },
    });
  const router = useRouter();
  const query = router.query as unknown as QueryParams;

  useEffect(() => {
    setCurrentPage(parseInt(query.page, 10));
  }, [router.query]);

  useEffect(() => {
    if (!window.location.search) {
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          page: 1,
          pageSize: 10,
        },
      });
      setPageSize(10);
    }
  }, []);

  const onPageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        page: nextPage,
      },
    });
  };

  const onPageLimitChange = (nextPageSize: string): void => {
    setPageSize(Number(nextPageSize));
    setCurrentPage(1);
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        page: 1,
        pageSize: nextPageSize,
      },
    });
  };

  return (
    <PaginationUI
      pagesCount={pagesCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    >
      <HStack>
        <PaginationPrevious>Previous</PaginationPrevious>
        <PaginationPageGroup>
          <HStack>
            {pages.map((page: number) => (
              <PaginationPage
                _current={{
                  bg: 'green.300',
                  fontSize: 'sm',
                  w: 7,
                }}
                key={`pagination_page_${page}`}
                page={page}
                w="40px"
              />
            ))}
          </HStack>
        </PaginationPageGroup>
        <PaginationNext>Next</PaginationNext>
        <HStack>
          <Text>Page Size</Text>
          <Select
            value={router.query?.pageSize}
            ml={3}
            onChange={(v) => onPageLimitChange(v.target.value)}
            w={40}
          >
            {customPageSize.map((pageSize: number) => (
              <option value={pageSize} key={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>
    </PaginationUI>
  );
}

export { PaginationTable };
