import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import {
  AllEventResponse,
  GetEventByIdRequest,
  GetEventByIdResponse,
  CreateEventRequest,
  GetEventDataRequest,
  GetEventDataReponse,
  GetAllEventRequest,
  GetTrackingGroupResponse,
  GetDetailTrackingGroupRequest,
  GetDetailTrackingGroupResponse,
  CommonRequest,
} from './event';
import {
  getDetailTrackingGroup,
  GetDetailTrackingGroupError,
  createEvent,
  CreateEventError,
  editEvent,
  getAllEvent,
  GetAllEventError,
  getEventById,
  GetEventByIdError,
  getEventDataByEventName,
  GetTrackingGroupsError,
  getTrackingGroup,
} from './eventService';

type EventKey = Array<string | GetAllEventRequest>;
type EventIdKey = Array<string | GetEventByIdRequest>;
type EventDataKey = Array<string | GetEventDataRequest>;
type TrackingGroupKey = Array<string | CommonRequest>;
type DetailTrackingGroupKey = Array<string | GetDetailTrackingGroupRequest>;

function useGetDetailTrackingGroup<TData = GetDetailTrackingGroupResponse>(
  payload: GetDetailTrackingGroupRequest,
  options?: UseQueryOptions<
    GetDetailTrackingGroupResponse,
    GetDetailTrackingGroupError,
    TData,
    DetailTrackingGroupKey
  >,
) {
  return useQuery(
    ['detailTrackingGroup', payload],
    () => getDetailTrackingGroup(payload),
    options,
  );
}

function useGetTrackingGroups<TData = GetTrackingGroupResponse>(
  payload: CommonRequest,
  options?: UseQueryOptions<
    GetTrackingGroupResponse,
    GetTrackingGroupsError,
    TData,
    TrackingGroupKey
  >,
) {
  return useQuery(
    ['trackingGroup', payload],
    () => getTrackingGroup(payload),
    options,
  );
}

function useGetAllEvent<TData = AllEventResponse>(
  payload: GetAllEventRequest,
  options?: UseQueryOptions<
    AllEventResponse,
    GetAllEventError,
    TData,
    EventKey
  >,
) {
  return useQuery(['event', payload], () => getAllEvent(payload), options);
}

function useGetEventById<TData = GetEventByIdResponse>(
  payload: GetEventByIdRequest,
  options?: UseQueryOptions<
    GetEventByIdResponse,
    GetEventByIdError,
    TData,
    EventIdKey
  >,
) {
  return useQuery(['eventById', payload], () => getEventById(payload), options);
}

function useCreateEvent(
  options?: UseMutationOptions<unknown, CreateEventError, CreateEventRequest>,
) {
  return useMutation(
    (payload: CreateEventRequest) => createEvent(payload),
    options,
  );
}

function useEditEvent(
  options?: UseMutationOptions<unknown, CreateEventError, CreateEventRequest>,
) {
  return useMutation(
    (payload: CreateEventRequest) => editEvent(payload),
    options,
  );
}

function useGetEventData<TData = GetEventDataReponse>(
  payload: GetEventDataRequest,
  options?: UseQueryOptions<
    GetEventDataReponse,
    GetAllEventError,
    TData,
    EventDataKey
  >,
) {
  return useQuery(
    ['event', payload],
    () => getEventDataByEventName(payload),
    options,
  );
}

export {
  useGetDetailTrackingGroup,
  useGetTrackingGroups,
  useGetEventData,
  useGetAllEvent,
  useGetEventById,
  useCreateEvent,
  useEditEvent,
};
