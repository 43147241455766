import {
  GetDetailTrackingGroupRequest,
  GetDetailTrackingGroupResponse,
  AllEventResponse,
  GetEventByIdRequest,
  GetEventByIdResponse,
  CreateEventRequest,
  CommonCreateOrEditResponse,
  GetEventDataReponse,
  GetEventDataRequest,
  GetAllEventRequest,
  CommonRequest,
  GetTrackingGroupResponse,
} from './event';

import {
  getAllEventExn,
  GetAllEventError,
  getEventByIdExn,
  GetEventByIdError,
  createEventExn,
  CreateEventError,
  editEventByIdExn,
  EditEventByIdError,
  getEventDataByEventNameExn,
  GetEventDataByEventNameError,
  getTrackingGroupsExn,
  GetTrackingGroupsError,
  getDetailTrackingGroupExn,
  GetDetailTrackingGroupError,
  ETLConfigGeneratorError,
  GetETLConfigGeneratorError,
} from '@/__generated__/__services__/rg_data_cms_api/RgDataCmsApi';

const getDetailTrackingGroup = async (
  payload: GetDetailTrackingGroupRequest,
): Promise<GetDetailTrackingGroupResponse> => {
  return await getDetailTrackingGroupExn(payload);
};

const getTrackingGroup = async (
  payload: CommonRequest,
): Promise<GetTrackingGroupResponse> => {
  return await getTrackingGroupsExn(payload);
};

const getAllEvent = async (
  payload: GetAllEventRequest,
): Promise<AllEventResponse> => {
  return await getAllEventExn(payload);
};

const getEventById = async (
  payload: GetEventByIdRequest,
): Promise<GetEventByIdResponse> => {
  return await getEventByIdExn(payload).then((data) => ({
    event: {
      ...data.event,
      eventProposals: {
        proposals:
          data.event.eventProposals?.map((proposal) => ({
            ...proposal,
            eventName: data.event.name,
          })) || [],
        metadata: {
          pagination: null,
        },
      },
    },
    metadata: data.metadata,
  }));
};

const createEvent = async (
  payload: CreateEventRequest,
): Promise<CommonCreateOrEditResponse> => {
  return await createEventExn(payload);
};

const editEvent = async (
  payload: CreateEventRequest,
): Promise<CommonCreateOrEditResponse> => {
  return await editEventByIdExn(payload);
};

const getEventDataByEventName = async (
  payload: GetEventDataRequest,
): Promise<GetEventDataReponse> => {
  return await getEventDataByEventNameExn(
    {
      ...payload,
      eventNames: payload.eventNames,
      memberSerials:
        payload.memberSerials === ''
          ? []
          : payload.memberSerials.split(',').map((data) => data.trim()),
      uaClients:
        payload.uaClients === ''
          ? []
          : payload.uaClients.split(',').map((data) => data.trim()),
      country: payload.country,
    },
    {
      arrayFormat: 'repeat',
    },
  ).then((data) => {
    return {
      schemaData: JSON.parse(data.schemaData),
    };
  });
};

export {
  getDetailTrackingGroup,
  getAllEvent,
  getEventById,
  createEvent,
  editEvent,
  getEventDataByEventName,
  getTrackingGroup,
};

export type {
  GetDetailTrackingGroupError,
  GetEventDataByEventNameError,
  GetAllEventError,
  GetEventByIdError,
  CreateEventError,
  EditEventByIdError,
  GetTrackingGroupsError,
  ETLConfigGeneratorError,
  GetETLConfigGeneratorError,
};
